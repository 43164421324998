import React from 'react';

import { default as bemCssModules } from 'bem-css-modules';
import { default as BoardModuleCss } from './board.module.css';
import { Gif } from '../../components/gif/gif';
import { Overlay } from '../../components/overlay/overlay';

const style = bemCssModules(BoardModuleCss);

export const Board: React.FC = () => {
    return (
        <div className={style()}>
            <Gif></Gif>
            <Overlay></Overlay>
        </div>
    );
};
