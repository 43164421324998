import { MotivatorsStore } from './motivatorsStore';

export interface IRootStore {
    rootStore: RootStore;
}

export class RootStore {
    public readonly motivatorsStore: MotivatorsStore;

    public constructor() {
        this.motivatorsStore = new MotivatorsStore();
    }
}
