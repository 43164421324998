import React from 'react';
import { StoreContext } from './storeProvider';
import { MotivatorsStore } from './motivatorsStore';

export function useMotivatorsStore(): MotivatorsStore {
    const rootStore = React.useContext(StoreContext);

    if (!rootStore) {
        throw new Error('Missing RootStore provider');
    }

    return rootStore.motivatorsStore;
}
