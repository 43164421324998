import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { default as bemCssModules } from 'bem-css-modules';
import { default as GifModuleCss } from './gif.module.scss';
import { getRandomGif } from '../../utils/gifsProvider';
import { GifImage } from '../../models/gifImage';
import { getRandomFatGuyPhrase } from '../../utils/wordsProvider';
import { useMotivatorsStore } from '../../stores/hooks';
import { typeEnum } from '../../models/typeEnum';

const style = bemCssModules(GifModuleCss);

const Gif: React.FC = () => {
    const [gif, setGif] = useState<GifImage | null>(null);
    const { id, type, next } = useMotivatorsStore();

    if (type != typeEnum.gif){
        return null;
    }

    useEffect(() => {
        getRandomGif(getRandomFatGuyPhrase()).then((gifObj) => {
            if (gifObj == null) {
                next();
            }
            setGif(gifObj);
        });
    }, [id]);

    return (
        <div className={style()} style={{ backgroundImage: `url(${gif?.url})` }}>
            <img
                className={style('attribution')}
                src={'//www.gstatic.com/tenor/web/attribution/PB_tenor_logo_grey_vertical.svg'}
                alt="Powered by tenor"
            />
        </div>
    );
};

const GifConsumer = observer(Gif);
export { GifConsumer as Gif };
