const fatGuys = [
    'fat guy',
    'fat men',
    'fat man sexy',
    'fat man dancing',
    'fat guy eating',
    'fat man belly',
    'fat man underwear',
    'fat man boobs',
];

export const getRandomFatGuyPhrase = (): string => fatGuys[Math.floor(Math.random() * fatGuys.length)];
