import React from 'react';

import { default as bemCssModules } from 'bem-css-modules';
import { default as OverlayModuleCss } from './overlay.module.scss';
import { useMotivatorsStore } from '../../stores/hooks';

const style = bemCssModules(OverlayModuleCss);

export const Overlay: React.FC = () => {
    const { next } = useMotivatorsStore();

    const overlayClick = () => {
        next();
    };

    return <div className={style()} onClick={overlayClick}></div>;
};
