import React from 'react';
import { default as bemCssModules } from 'bem-css-modules';
import './app.scss';

import { ErrorBoundary } from './containers/errorBoundary/errorBoundary';
import { StoreProvider } from './stores/storeProvider';
import { Board } from './containers/board/board';

bemCssModules.setSettings({
    modifierDelimiter: '--',
    throwOnError: true,
});

export const App: React.FC = () => {
    return (
        <ErrorBoundary>
            <StoreProvider>
                <Board />
            </StoreProvider>
        </ErrorBoundary>
    );
};
