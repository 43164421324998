import React from 'react';

interface ErrorBoundaryProps {
    children: JSX.Element;
}

interface ErrorBoundaryState {
    error?: Error;
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    public readonly state: ErrorBoundaryState = {};

    public static getDerivedStateFromError(error: Error): ErrorBoundaryState {
        return { error };
    }

    public render(): JSX.Element {
        const { error }: ErrorBoundaryState = this.state;

        if (error) {
            return (
                <div>
                    <h1>Ooops... error found :(</h1>
                    <pre>{error.message}</pre>
                </div>
            );
        }

        const { children }: ErrorBoundaryProps = this.props;

        return children;
    }
}
