import axios from 'axios';
import { GifImage } from '../models/gifImage';

export const getRandomGif = async (query: string): Promise<GifImage | null> => {
    try {
        const response = await axios.get(
            `https://api.tenor.com/v1/random?q=${query}&key=9VCC977KBWIC&limit=10&media_filter=minimal`,
        );

        const { title, media } = response?.data?.results[0];
        const { url, dims } = media[0]?.gif;
        if (url) {
            return {
                url,
                width: dims[0],
                height: dims[1],
                title,
            }
        } else {
            return null;
        }
    } catch {
        return null;
    }
};
