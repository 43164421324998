import { observable, action } from 'mobx';
import { typeEnum } from '../models/typeEnum';

export class MotivatorsStore {
    @observable
    public id = 0;

    @observable
    public type = typeEnum.gif;

    @action
    public next = (): void => {
        this.id++;
        this.type = typeEnum.gif;
    };
}
