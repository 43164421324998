import React from 'react';
import ReactDOM from 'react-dom';
import 'mobx-react-lite/batchingForReactDom';
import 'babel-polyfill';

import { App } from './app';

const rootElement = document.getElementById('fit-motivator');

ReactDOM.render(<App />, rootElement);
